import React from "react";
import styled from "styled-components";

// Container para os botões
const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  gap: 40px; /* Espaçamento entre os botões */
`;

// Styled-component para os botões da loja
const StoreButton = styled.a<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 12px;
  padding: 24px 32px;
  width: 320px; /* Largura fixa do botão */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "#333" : "#334")};
  }

  /* Estilos adicionais para quando o botão está desabilitado */
  &.disabled {
    background-color: #333;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

// Container interno para o conteúdo do botão
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Texto ao lado do ícone
const ButtonText = styled.span`
  margin-left: 12px;
`;

// Imagem do ícone da loja
const StoreImage = styled.img`
  width: 60px;
  height: 60px;
`;

// Label abaixo do conteúdo do botão
const TextLabel = styled.span<{ disabled?: boolean }>`
  font-size: 16px;
  margin-top: 12px;
  color: #fff;
`;

const DownloadButtonsOverlay: React.FC = () => {
  return (
    <ButtonsContainer>
      {/* Botão App Store Desabilitado */}
      <StoreButton
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        className="disabled" // Adiciona a classe 'disabled' para aplicar estilos desabilitados
        disabled
      >
        <ButtonContent>
          <StoreImage src="/apple.png" alt="App Store" />
          <ButtonText>App Store</ButtonText>
        </ButtonContent>
        <TextLabel>Em Breve</TextLabel> {/* Altera o texto para "Em Breve" */}
      </StoreButton>

      {/* Botão Google Play */}
      <StoreButton
        href="https://play.google.com/store/apps/details?id=next.com.meachou"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonContent>
          <StoreImage src="/google.png" alt="Google Play" />
          <ButtonText>Google Play</ButtonText>
        </ButtonContent>
        <TextLabel>Baixe agora</TextLabel>
      </StoreButton>
    </ButtonsContainer>
  );
};

export default DownloadButtonsOverlay;
