import React from "react";
import { Box, Container, Typography } from "@mui/material";

const PoliticaDePrivacidade: React.FC = () => {
  return (
    <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 6 }}>
      <Container maxWidth="lg">
        <Typography variant="h3" sx={{ mb: 4, fontWeight: "bold" }}>
          Política de Privacidade
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
          Termos e Condições
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Estes termos e condições se aplicam ao aplicativo Me Achou (doravante
          denominado "Aplicativo") para dispositivos móveis que foi criado pela
          Next Innovation Technologies Ltda (doravante denominada "Provedor de
          Serviços") como um serviço gratuito.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Ao baixar ou utilizar o Aplicativo, você concorda automaticamente com
          os seguintes termos. É altamente recomendável que você leia e entenda
          completamente estes termos antes de usar o Aplicativo.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Cópias não autorizadas, modificações do Aplicativo, qualquer parte do
          Aplicativo ou nossas marcas registradas são estritamente proibidas.
          Quaisquer tentativas de extrair o código-fonte do Aplicativo, traduzir
          o Aplicativo para outros idiomas ou criar versões derivadas não são
          permitidas. Todas as marcas registradas, direitos autorais, direitos
          de banco de dados e outros direitos de propriedade intelectual
          relacionados ao Aplicativo permanecem como propriedade do Provedor de
          Serviços.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          O Provedor de Serviços se dedica a garantir que o Aplicativo seja o
          mais benéfico e eficiente possível. Como tal, eles se reservam o
          direito de modificar o Aplicativo ou cobrar por seus serviços a
          qualquer momento e por qualquer motivo. O Provedor de Serviços garante
          que quaisquer cobranças pelo Aplicativo ou seus serviços serão
          claramente comunicadas a você.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          O Aplicativo armazena e processa dados pessoais que você forneceu ao
          Provedor de Serviços para fornecer o Serviço. É sua responsabilidade
          manter a segurança do seu telefone e o acesso ao Aplicativo. O
          Provedor de Serviços desaconselha fortemente o jailbreak ou rooting do
          seu telefone, o que envolve a remoção de restrições e limitações de
          software impostas pelo sistema operacional oficial do seu dispositivo.
          Tais ações podem expor seu telefone a malware, vírus, programas
          maliciosos, comprometer os recursos de segurança do seu telefone e
          podem fazer com que o Aplicativo não funcione corretamente ou não
          funcione.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Observe que o Aplicativo utiliza serviços de terceiros que têm seus
          próprios Termos e Condições. Abaixo estão os links para os Termos e
          Condições dos provedores de serviços de terceiros usados pelo
          Aplicativo:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <a href="https://policies.google.com/terms">
            Serviços do Google Play
          </a>
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          Esteja ciente de que o Provedor de Serviços não assume
          responsabilidade por certos aspectos. Algumas funções do Aplicativo
          exigem uma conexão ativa com a internet, que pode ser Wi-Fi ou
          fornecida pelo seu provedor de rede móvel. O Provedor de Serviços não
          pode ser responsabilizado se o Aplicativo não funcionar em capacidade
          total devido à falta de acesso ao Wi-Fi ou se você tiver esgotado sua
          franquia de dados.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Se você estiver usando o aplicativo fora de uma área Wi-Fi, esteja
          ciente de que os termos do contrato da sua operadora de rede móvel
          ainda se aplicam. Consequentemente, você pode incorrer em cobranças da
          sua operadora de celular pelo uso de dados durante a conexão com o
          aplicativo, ou outras cobranças de terceiros. Ao usar o aplicativo,
          você aceita a responsabilidade por quaisquer cobranças, incluindo
          cobranças de dados em roaming se você usar o aplicativo fora do seu
          território de origem (ou seja, região ou país) sem desabilitar o
          roaming de dados. Se você não for o pagador da conta do dispositivo no
          qual está usando o aplicativo, presume-se que você obteve permissão do
          pagador da conta.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Da mesma forma, o Provedor de Serviços nem sempre pode assumir a
          responsabilidade pelo seu uso do aplicativo. Por exemplo, é sua
          responsabilidade garantir que seu dispositivo permaneça carregado. Se
          seu dispositivo ficar sem bateria e você não conseguir acessar o
          Serviço, o Provedor de Serviços não pode ser responsabilizado.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Em termos de responsabilidade do Provedor de Serviços pelo seu uso do
          aplicativo, é importante observar que, embora eles se esforcem para
          garantir que ele esteja atualizado e preciso o tempo todo, eles
          dependem de terceiros para fornecer informações a eles para que possam
          disponibilizá-las a você. O Provedor de Serviços não aceita nenhuma
          responsabilidade por qualquer perda, direta ou indireta, que você
          experimente como resultado de confiar inteiramente nesta
          funcionalidade do aplicativo.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          O Provedor de Serviços pode desejar atualizar o aplicativo em algum
          momento. O aplicativo está disponível atualmente de acordo com os
          requisitos para o sistema operacional (e para quaisquer sistemas
          adicionais para os quais eles decidam estender a disponibilidade do
          aplicativo) pode mudar, e você precisará baixar as atualizações se
          quiser continuar usando o aplicativo. O Provedor de Serviços não
          garante que sempre atualizará o aplicativo para que seja relevante
          para você e/ou compatível com a versão específica do sistema
          operacional instalada no seu dispositivo. No entanto, você concorda em
          sempre aceitar atualizações para o aplicativo quando oferecidas a
          você.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          O Provedor de Serviços também pode desejar deixar de fornecer o
          aplicativo e pode encerrar seu uso a qualquer momento sem fornecer
          aviso de rescisão a você. A menos que eles informem o contrário, após
          qualquer rescisão:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>
              (a) os direitos e licenças concedidos a você nestes termos
              terminarão;
            </li>
            <li>
              (b) você deve parar de usar o aplicativo e (se necessário)
              excluí-lo do seu dispositivo.
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
          Alterações a estes Termos e Condições
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          O Provedor de Serviços pode atualizar periodicamente seus Termos e
          Condições. Portanto, é aconselhável que você revise esta página
          regularmente para quaisquer alterações. O Provedor de Serviços
          notificará você sobre quaisquer alterações publicando os novos Termos
          e Condições nesta página. Estes termos e condições são efetivos a
          partir de 12 de novembro de 2024.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
          Contate-nos
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Caso tenha alguma dúvida ou sugestão sobre os Termos e Condições, não
          hesite em entrar em contato com o Provedor de Serviços pelo e-mail{" "}
          <a href="mailto:contato@meachou.com">contato@meachou.com</a>.
        </Typography>

        <Typography variant="caption" sx={{ mt: 2, display: "block" }}>
          Esta página de Termos e Condições foi gerada pelo App Privacy Policy
          Generator.
        </Typography>
      </Container>
    </Box>
  );
};

export default PoliticaDePrivacidade;
