import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PoliticaDePrivacidade from "./politics"; // Certifique-se de que o caminho está correto

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Política de Privacidade
      </Typography>
      <Box>
        <PoliticaDePrivacidade />
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
