/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { styled, keyframes, useMediaQuery } from "@mui/system";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import CommentIcon from "@mui/icons-material/Comment";
import EventIcon from "@mui/icons-material/Event";
import WorkIcon from "@mui/icons-material/Work";
import StarIcon from "@mui/icons-material/Star";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import logo from "./assets/m-logo.png";
import backgroundImage from "./assets/background-image.png";

import "@fontsource/poppins";
import DownloadButtonsOverlay from "./components/downloadButtons";
import { Link as RouterLink } from "react-router-dom"; // Importação necessária para roteamento

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideInLeft = keyframes`
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const slideInUp = keyframes`
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const AnimatedBox = styled(Box)<{ animation: any; animationDelay?: string }>(
  ({ animation, animationDelay = "0s" }) => ({
    animation: `${animation} 1s ease forwards`,
    animationDelay,
  })
);

const HomePage: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isLargeScreen = useMediaQuery("(min-width:1500px)");

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const navItems = ["recursos", "sobre", "preços"];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        bgcolor: "black",
        color: "white",
        fontFamily: "Poppins, sans-serif",
        overflowX: "hidden",
      }}
    >
      <AppBar position="sticky" sx={{ bgcolor: "grey.900", zIndex: 1201 }}>
        <Toolbar sx={{ px: { xs: 2, md: 4 }, height: 56 }}>
          <a
            href="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Me achou"
              sx={{
                height: { xs: 70, sm: 70, md: 70 },
              }}
            />
          </a>
          <Box
            sx={{
              ml: "auto",
              display: { xs: "none", sm: "flex" },
              gap: { xs: 2, sm: 3 },
              alignItems: "center",
            }}
          >
            {navItems.map((section) => (
              <Button
                key={section}
                onClick={() => scrollToSection(section)}
                sx={{
                  textTransform: "none",
                  fontWeight: "medium",
                  color: "inherit",
                  fontSize: "small",
                  "&:hover": { color: "#49A3F2" },
                }}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Button>
            ))}

            {isLargeScreen && (
              <Button
                onClick={() => scrollToSection("download")}
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontWeight: "medium",
                  color: "white",
                  fontSize: "small",
                  bgcolor: "#49A3F2",
                  "&:hover": { bgcolor: "#3182CE" },
                  ml: 2,
                }}
              >
                Baixe o Me achou Agora
              </Button>
            )}

            {/* Link para a Política de Privacidade na barra de navegação */}
            <Button
              component={RouterLink}
              to="/politica-de-privacidade"
              sx={{
                textTransform: "none",
                fontWeight: "medium",
                color: "inherit",
                fontSize: "small",
                "&:hover": { color: "#49A3F2" },
              }}
            >
              Política de Privacidade
            </Button>
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "block", sm: "none" }, ml: "auto" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250, bgcolor: "grey.900", height: "100%" }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {navItems.map((text) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => scrollToSection(text)}>
                  <ListItemText
                    primary={text.charAt(0).toUpperCase() + text.slice(1)}
                    sx={{ color: "white" }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            {isLargeScreen && (
              <ListItem disablePadding>
                <ListItemButton onClick={() => scrollToSection("download")}>
                  <ListItemText
                    primary="Baixe o Me achou Agora"
                    sx={{ color: "white" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {/* Link para a Política de Privacidade no menu lateral */}
            <ListItem disablePadding>
              <ListItemButton
                component={RouterLink}
                to="/politica-de-privacidade"
              >
                <ListItemText
                  primary="Política de Privacidade"
                  sx={{ color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box component="main" sx={{ overflowX: "hidden" }}>
        <AnimatedBox sx={{ width: "100%" }} animation={fadeIn}>
          <Box
            sx={{
              width: "100%",
              height: {
                xs: "65vh",
                sm: isLargeScreen ? "100vh" : "60vh",
              },
              backgroundImage: isLargeScreen
                ? `url(${backgroundImage})`
                : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginTop: "0px",
              paddingTop: "0px",
              position: "relative",
            }}
          >
            {!isLargeScreen && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  padding: "20px",
                  borderRadius: "8px",
                  maxWidth: "800px",
                  width: "90%",
                  textAlign: "center",
                  boxSizing: "border-box",
                  marginBottom: { xs: "20px", sm: "60px" },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "2rem", sm: "3rem", xl: "4rem" },
                    mb: 2,
                  }}
                >
                  Baixe o Me achou Agora
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", md: "1.25rem" },
                    mb: 3,
                  }}
                >
                  Me achou é a nova rede social que conecta empresas e clientes
                  de forma rápida e descomplicada. Explore, interaja e descubra
                  oportunidades em um só lugar.
                </Typography>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                    alignItems: "stretch",
                    justifyContent: "center",
                  }}
                >
                  {/* Botão App Store Desabilitado */}
                  <Button
                    variant="contained"
                    startIcon={<AppleIcon sx={{ color: "white" }} />}
                    disabled
                    sx={{
                      bgcolor: "#333",
                      "&:hover": { bgcolor: "#333" },
                      "&.Mui-disabled": {
                        bgcolor: "#333",
                        color: "white",
                        opacity: 1,
                      },
                      cursor: "not-allowed",
                      width: "100%",
                    }}
                  >
                    App Store
                  </Button>
                  {/* Botão Google Play */}
                  <Button
                    variant="contained"
                    href="https://play.google.com/store/apps/details?id=next.com.meachou"
                    startIcon={<AndroidIcon sx={{ color: "white" }} />}
                    sx={{
                      bgcolor: "#A4C639",
                      "&:hover": { bgcolor: "#8BB12C" },
                      width: "100%",
                    }}
                  >
                    Google Play
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </AnimatedBox>

        {isLargeScreen && (
          <AnimatedBox
            id="download"
            sx={{
              width: "100%",
              py: { xs: 4, md: 8 },
              px: 2,
              bgcolor: "grey.900",
            }}
            animation={fadeIn}
          >
            <Container maxWidth="lg">
              <AnimatedBox animation={slideInLeft}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
                    mb: 10,
                  }}
                >
                  Baixe agora
                </Typography>
              </AnimatedBox>
              <Grid container spacing={1}>
                <DownloadButtonsOverlay />
              </Grid>
            </Container>
          </AnimatedBox>
        )}

        <AnimatedBox
          id="recursos"
          sx={{
            width: "100%",
            py: { xs: 4, md: 8 },
            px: 2,
            bgcolor: "grey.900",
          }}
          animation={fadeIn}
        >
          <Container maxWidth="lg">
            <AnimatedBox animation={slideInLeft}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
                  mb: 6,
                }}
              >
                Recursos do Me achou
              </Typography>
            </AnimatedBox>
            <Grid container spacing={6}>
              {[
                {
                  icon: <LanguageIcon sx={{ color: "#49A3F2" }} />,
                  text: "Conexão rápida entre empresas e clientes",
                },
                {
                  icon: <StarIcon sx={{ color: "#49A3F2" }} />,
                  text: "Perfis personalizados para empresas",
                },
                {
                  icon: <PostAddIcon sx={{ color: "#49A3F2" }} />,
                  text: "Publicações dinâmicas e interativas",
                },
                {
                  icon: <LocationOnIcon sx={{ color: "#49A3F2" }} />,
                  text: "Busca avançada por categoria e localização",
                },
                {
                  icon: <CommentIcon sx={{ color: "#49A3F2" }} />,
                  text: "Comentários e curtidas em publicações",
                },
                {
                  icon: <EventIcon sx={{ color: "#49A3F2" }} />,
                  text: "Gerenciamento e confirmação de presença em eventos",
                },
                {
                  icon: <WorkIcon sx={{ color: "#49A3F2" }} />,
                  text: "Listagem e criação de vagas de emprego",
                },
                {
                  icon: <AssignmentIndIcon sx={{ color: "#49A3F2" }} />,
                  text: "Avaliações e feedbacks de lojas",
                },
                {
                  icon: <LocalOfferIcon sx={{ color: "#49A3F2" }} />,
                  text: "Visualização de rankings de lojas",
                },
                {
                  icon: <StarIcon sx={{ color: "#49A3F2" }} />,
                  text: "Visualização das mais visitadas",
                },
                // Novas funcionalidades adicionadas
                {
                  icon: <CheckCircleIcon sx={{ color: "#49A3F2" }} />,
                  text: "Reserva de Produtos",
                },
                {
                  icon: <LocalOfferIcon sx={{ color: "#49A3F2" }} />,
                  text: "Comercialização de Produtos",
                },
              ].map((feature, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {feature.icon}
                    <Typography>{feature.text}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </AnimatedBox>

        <AnimatedBox
          id="sobre"
          sx={{ width: "100%", py: { xs: 6, md: 12, lg: 18 }, px: 2 }}
          animation={fadeIn}
        >
          <Container maxWidth="lg">
            <AnimatedBox animation={slideInLeft}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
                  mb: 8,
                }}
              >
                Sobre o Me achou
              </Typography>
            </AnimatedBox>
            <AnimatedBox animation={slideInLeft} animationDelay="0.2s">
              <Typography
                sx={{
                  maxWidth: 800,
                  color: "gray",
                  fontSize: { xs: "1rem", md: "1.25rem" },
                }}
              >
                O Me achou nasceu da necessidade de simplificar a conexão entre
                empresas e clientes. Nossa plataforma oferece uma experiência
                única, permitindo que usuários descubram novos negócios,
                interajam com suas marcas favoritas e aproveitem oportunidades
                exclusivas. Para as empresas, o Me achou é uma poderosa
                ferramenta de visibilidade e engajamento, proporcionando um
                canal direto com seu público-alvo.
              </Typography>
            </AnimatedBox>
          </Container>
        </AnimatedBox>

        <AnimatedBox
          id="preços"
          sx={{
            width: "100%",
            py: { xs: 4, md: 8 },
            px: 2,
            bgcolor: "grey.800",
          }}
          animation={fadeIn}
        >
          <Container maxWidth="lg">
            <AnimatedBox animation={slideInLeft}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
                  mb: 6,
                }}
              >
                Planos e Preços
              </Typography>
            </AnimatedBox>
            <Grid container spacing={6}>
              {[
                {
                  title: "Básico",
                  price: "Grátis",
                  features: [
                    { text: "Avaliação de Lojas", icon: <CheckCircleIcon /> },
                    {
                      text: "Confirmação de Presença em Eventos",
                      icon: <EventIcon />,
                    },
                    { text: "Encontrar Lojas e Empregos", icon: <WorkIcon /> },
                    {
                      text: "Comentar e Curtir Publicações",
                      icon: <CommentIcon />,
                    },
                    {
                      text: "Visualizar Rankings de Lojas",
                      icon: <StarIcon />,
                    },
                    {
                      text: "Visualizar as Mais Visitadas",
                      icon: <StarIcon />,
                    },
                    // Nova funcionalidade adicionada
                    {
                      text: "Reserva de Produtos",
                      icon: <CheckCircleIcon />,
                    },
                  ],
                },
                {
                  title: "Pro",
                  price: "R$19,90/mês",
                  features: [
                    { text: "Criação de Eventos", icon: <EventIcon /> },
                    { text: "Criação de Publicações", icon: <PostAddIcon /> },
                    {
                      text: "Criação de Vagas de Emprego",
                      icon: <AssignmentIndIcon />,
                    },
                    {
                      text: "Recebimento de Feedback de Avaliações",
                      icon: <CheckCircleIcon />,
                    },
                    {
                      text: "Participação em Rankings de Lojas",
                      icon: <StarIcon />,
                    },
                    { text: "Criação de Campanhas", icon: <LocalOfferIcon /> },
                    // Atualização da funcionalidade
                    {
                      text: "Criação de Produtos para Comercialização e Reserva de Produtos",
                      icon: <PostAddIcon />,
                    },
                  ],
                },
              ].map((plan, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <AnimatedBox
                    animation={slideInUp}
                    animationDelay={`${index * 0.2}s`}
                  >
                    <Card
                      sx={{
                        bgcolor: "grey.900",
                        border: "1px solid",
                        borderColor: "grey.700",
                        color: "white",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        minHeight: 300,
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", color: "white" }}
                        >
                          {plan.title}
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{ fontWeight: "bold", mt: 2, color: "white" }}
                        >
                          {plan.price}
                        </Typography>
                        <Box sx={{ mt: 4 }}>
                          {plan.features.map((feature, idx) => (
                            <Box
                              key={idx}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                              }}
                            >
                              {feature.icon}
                              <Typography sx={{ ml: 1 }}>
                                {feature.text}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </CardContent>
                    </Card>
                  </AnimatedBox>
                </Grid>
              ))}
            </Grid>
          </Container>
        </AnimatedBox>
      </Box>

      <Box
        component="footer"
        sx={{
          py: 6,
          width: "100%",
          bgcolor: "grey.900",
          borderTop: "1px solid",
          borderColor: "grey.800",
          color: "white",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Redes Sociais
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <IconButton
                  href="https://www.instagram.com/meachoubr?igsh=dm5wNjYyOHB4cWl6"
                  target="_blank"
                  rel="noreferrer"
                  sx={{ color: "#E4405F" }}
                >
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Endereço
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <LocationOnIcon sx={{ mr: 1, color: "primary.main" }} />
                <Typography>Rua Wanderley Rodrigues Pereira, 79</Typography>
              </Box>
              <Typography sx={{ ml: 4 }}>Marília, SP</Typography>
              <Typography sx={{ ml: 4 }}>CEP: 17535-533</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Contato
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <EmailIcon sx={{ mr: 1, color: "primary.main" }} />
                <Link
                  href="mailto:contato@meachou.com"
                  sx={{ color: "white", textDecoration: "none" }}
                >
                  contato@meachou.com
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: 6,
              textAlign: "center",
              borderTop: "1px solid",
              borderColor: "grey.800",
              pt: 3,
            }}
          >
            <Typography variant="body2" sx={{ mb: 2 }}>
              © {new Date().getFullYear()} Next Innovation Technologies Ltda.
              Todos os direitos reservados.
            </Typography>
            <Link
              component={RouterLink}
              to="/politica-de-privacidade"
              sx={{
                color: "white",
                textDecoration: "underline",
              }}
            >
              Política de Privacidade
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;
